/* Custom styles for the toggle switch */
  .toggle-slider {
    background-color: #C2C2C2;
    border-radius: 25px;
    /* Other styles here */
  }
  
  .toggle-slider.checked {
    background-color: #5C42F9;
    /* Other styles here */
  }
  