.line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4; /* Number of lines to show */
    max-height: 6rem; /* Adjust based on your font size and line height */
  }

  
  
  
  