.line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* Number of lines to show */
    max-height: 3rem; /* Adjust based on your font size and line height */
  }

  .single-line {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
    max-width: 90%; /* Ensure the text stays within the container */
  }
  
  
  
  