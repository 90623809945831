.child:focus .parent{
    border-color: #5C42F9;
}

.child:active .parent{
    border-color: #5C42F9;
}

.child:hover .parent{
    border-color: #5C42F9;
}